<template>
    <div class="RecommendTable-container">
        <!-- 拒绝 -->
        <!--    <h1>VIP私密推荐</h1>-->
        <div class="tabs">
            <ul>
                <!--    <li :key="index" v-for="(item, index) in tabs" :class="{ tabClass: isTab === index }" @click="tabClick(index)">
                         {{ item }}
                        </li>-->
                <li :class="{ tabClass: isTab === 0 }" @click="tabClick(0)">
                    {{ $t('myProject.vipPrivateRecommendation') }}
                </li>
                <li :class="{ tabClass: isTab === 1 }" @click="tabClick(1)" v-if="$route.query.pd_type != '2'">
                    {{ $t('myProject.beInterested') }}
                </li>
                <li :class="{ tabClass: isTab === 2 }" @click="tabClick(2)" v-if="$route.query.pd_type != '2'">
                    {{ $t('myProject.memberRecommendation') }}
                </li>
                <li :class="{ tabClass: isTab === 3 }" @click="tabClick(3)" v-if="$route.query.pd_type != '2'">
                    {{ $t('myProject.have') }}
                </li>
            </ul>
        </div>
        <table>
            <thead>
            <tr>
                <td>
                    <label> <input @change="checkAll()" type="checkbox" v-model="checked"/> <span><!-- 全选 -->{{ $t('myProject.selectAll') }}</span>
                    </label>
                </td>
                <td :class="{'have-follow-col-2':isTab==3}">
                    <!-- 参与人 -->
                    {{ $t('myProject.participant') }}
                </td>
                <td v-if="isTab === 0">
                    <!-- 推荐原因 -->
                    {{ $t('myProject.reasonsForRecommendation') }}
                </td>
                <td>
                    <!-- 访谈时间 -->
                    {{ $t('myProject.interviewTime') }}
                </td>
                <td>
                    <!-- 操作 -->
                    {{ $t('myProject.operation') }}
                </td>
                <td>
                    <!-- 进度 -->
                    {{ $t('myProject.speedOfProgress') }}
                </td>
                <td>
                    <!-- 发起时间 -->
                    {{ $t('myProject.startTime') }}
                </td>
            </tr>
            </thead>
            <tbody>
            <tr :key="index" v-for="(item, index) in privacyList">
                <td>
                    <label><input :value="item.invitee_id" type="checkbox" v-model="checkModel"/></label>
                </td>
                <td>
                    <div class="recommend-people-info">
                        <img :src="item.invitee_image" alt="" class="rpi-head"/>
                        <div class="rpi-text">
                            <div class="rpi-name over">{{ item.invitee_name }}</div>
                            <div class="rpi-position over">{{ item.position }}</div>
                        </div>
                        <div @click="attention($event,1, item)" class="rpi-btn" v-if="isTab==3&&item.followed==0">{{
                            $t('myProject.immediateAttention') }}
                            <!--立即关注--></div>
                        <div @click="attention($event,2, item)" class="rpi-btn" v-if="isTab==3&&item.followed==1">{{
                            $t('myProject.unfollow') }}<!--取消关注--></div>
                    </div>
                </td>
                <td v-if="isTab === 0">
                    <div class="r-limit">
                        <!--<div class="r-reason" v-if="item.reason_show == 1">{{ item.introduce_reason }}</div>-->
                        <div @click="get_introduceTemplate(item)" class="r-reason-model" style="cursor: pointer"
                             v-if="item.reason_show == 1">
                            <!-- 推荐原因 -->
                            {{ $t('myProject.recommendedTemplate') }}
                        </div>
                        <div style="margin-left: 38px" v-else>-</div>
                    </div>
                </td>
                <td>
                    <div class="interview-time" v-if="item.invitation_time">{{ item.invitation_time }}</div>
                    <div v-else>-</div>
                </td>
                <td>
                    <div class="operation">
                        <!-- 私密推荐 -->
                        <div v-if="isTab == 0">
                            <div class="btn-op" v-if="item.progress == 3">
                                <div @click="gopage(index, item)" class="btn-op-item cur fl">
                                    <!-- 愿意免费沟通 -->
                                    {{ $t('myProject.willingToCommunicateForFree') }}
                                </div>
                                <div @click="dialogVisible = true, pd_name = 2, inv = item.interview_id,
                      dialogname = $t('vipRecommend.reasonsForRefuse')" class="btn-op-item ban fr cur"><!--拒绝原因-->
                                    <!-- 拒绝 -->
                                    {{ $t('myProject.refuse') }}
                                </div>
                            </div>
                            <div class="btn-op" style="display: flex; justify-content: center"
                                 v-else-if="item.progress == 7">
                                <div @click="dialogVisible = true, pd_name = 3,
                      dialogname = $t('bulletFrame.reasonForCancellation'), inv = item.interview_id"
                                     class="btn-op-item cur fl"
                                     v-if="isCan(item.invitation_time)">
                                    <!-- 取消预约 -->
                                    {{ $t('myProject.cancelReservation') }}
                                </div>
                                <!--{vip访谈}-->
                                <div @click="$router.push({path:'/videoRoom', query:{interviewId:item.interview_id, type:2, isPublisher:1}})"
                                     class="btn-op-item cur fl"
                                     style="margin-left: 10px"
                                     v-if="!isCan(item.invitation_time)"
                                >
                                    <!-- 访谈 -->
                                    {{ $t('myProject.interview') }}
                                </div>
                            </div>
                            <div class="btn-op" style="display: flex; justify-content: center"
                                 v-else-if="item.progress == 10&&item.is_evaluate == 0">
                                <div @click="dialogVisible = true, pd_name = 4, inv = item.interview_id,
                      dialogname = $t('vipRecommend.postedComment')" class="btn-op-item cur fl"><!--发表评论-->
                                    <!-- 立即评价 -->
                                    {{ $t('myProject.immediateEvaluation') }}
                                </div>
                            </div>
                            <div class="btn-op" v-else>-</div>
                        </div>
                        <div v-else>
                            <div class="btn-op" style="display: flex; justify-content: center"
                                 v-if="item.progress == 5">
                                <div @click="dialogVisible = true, pd_name = 1,
                      dialogname = $t('bulletFrame.reasonForCancellation'), inv = item.interview_id, print1(item)"
                                     class="btn-op-item cur fl"
                                     v-if="isCan(item.invitation_time)">
                                    <!-- 取消预约 -->
                                    {{ $t('myProject.cancelReservation') }}
                                </div>
                                <div @click="$router.push({path:'/videoRoom', query:{interviewId:item.interview_id,type:1,isPublisher:1}})"
                                     class="btn-op-item cur fl"
                                     style="margin-left: 10px"
                                     v-if="!isCan(item.invitation_time)">
                                    <!-- 访谈 -->
                                    {{ $t('myProject.interview') }}
                                </div>
                            </div>
                            <div class="btn-op" v-else-if="item.progress == 1">
                                <div @click="gopage(index, item)" class="btn-op-item cur fl">
                                    <!-- 愿意免费沟通 -->
                                    {{ $t('myProject.willingToCommunicateForFree') }}
                                </div>
                                <div @click=" dialogVisible = true; pd_name = 0; inv = item.interview_id;
                      dialogname = $t('vipRecommend.reasonsForRefuse');" class="btn-op-item ban fr cur">
                                    <!-- 拒绝 -->
                                    {{ $t('myProject.refuse') }}
                                </div>
                            </div>
                            <div class="btn-op" style="display: flex; justify-content: center"
                                 v-else-if="item.progress == 8&&item.is_evaluate == 0">
                                <div @click=" dialogVisible = true; pd_name = 4; inv = item.interview_id;
                      dialogname = $t('vipRecommend.postedComment');" class="btn-op-item cur fl">
                                    <!-- 立即评价 -->
                                    {{ $t('myProject.immediateEvaluation') }}
                                </div>
                            </div>
                            <div class="btn-op" v-else>-</div>
                        </div>
                    </div>
                </td>
                <td>
                    <ProgressBar :id="item.interview_id" v-if="item.progress_name">{{ item.progress_name }}
                    </ProgressBar>
                    <div class="no-op" v-else>-</div>
                    <div v-if="item.progress_name=='Rejected'">{{ item.reject_reason }}</div>
                </td>
                <td>
                    <div class="no-op" v-if="item.create_time">{{ item.create_time }}</div>
                    <div class="no-op" v-else>-</div>
                </td>
            </tr>
            </tbody>
        </table>
        <el-pagination
                :current-page.sync="pageNum"
                :page-size="pageSize"
                :total="total"
                @current-change="handleCurrentChange"
                layout="prev, pager, next,slot"
                style="text-align: end"
        >
            <slot>
                <div class="jump-page">
          <span>
            <!-- 前往 -->
            {{ $t('myProject.goTo') }}
          </span>
                    <input type="text" v-model.number="goPageNum"/>
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
        <el-dialog :title="dialogname" :visible.sync="dialogVisible" center custom-class="title-class" width="890px">
            <div style="display: flex; justify-content: center">
                <div class="form-wrap">
                    <div class="input-text-area" v-if="pd_name == 0 || pd_name == 2">
                        <div class="ita-label">
                            <span>* </span>{{ $t("vipRecommend.mobileNumber") }}
                        </div>
                        <div style="display: inline-block;">
                            <el-select filterable style="width:200px !important;height:50px;" v-model="many.mobileCode">
                                <el-option :key="item.id"
                                           :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                           :value="item.code" v-for="item in areaList.phoneCountriesCodeList">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                            <!--<span v-if="many.mobileCode" style="position:absolute;margin-left:-40px;">+{{many.mobileCode}}</span>-->
                            <div class="v-line"></div>
                            <input :placeholder="$t('myProject.pleaseInput')" class="iif-input" style="width:200px;"
                                   type="text" v-model="many.phone"/>
                        </div>
                    </div>
                    <div class="input-text-area" v-if="pd_name == 0  || pd_name == 2">
                        <div class="ita-label"><span>* </span>{{ $t("vipRecommend.email") }}</div>
                        <div class="ita-input-front">
                            <input :placeholder="$t('myProject.pleaseInput')" class="iif-input" type="text"
                                   v-model="many.data_mailbox"/>
                        </div>
                    </div>
                    <div class="input-text-area">
                        <div v-if="pd_name == 0  || pd_name == 2">
                            <div class="ita-label"><span>* </span>WhatsApp</div>
                            <div style="display: inline-block;">
                                <el-select filterable style="width:200px !important;height:50px;"
                                           v-model="many.whatsappCode">
                                    <el-option :key="item.id"
                                               :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                               :value="item.code" v-for="item in areaList.phoneCountriesCodeList">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                                <!--<span v-if="many.whatsappCode" style="position:absolute;margin-left:-40px;">+{{many.whatsappCode}}</span>-->
                                <div class="v-line"></div>
                                <input :placeholder="$t('myProject.pleaseInput')" class="iif-input" style="width:200px;"
                                       type="text" v-model="many.data_whatsApp"/>
                            </div>
                        </div>
                        <div v-if="pd_name == 4 || pd_name == 5">
                            <div class="people-wrap">
                                <img :src="details_data.initiator_avatar" alt="" class="people-head"/>

                                <span class="people-name">{{ details_data.initiator }}</span>
                                <span class="project-name">{{ details_data.project_name }}</span>
                            </div>
                            <div class="evaluate">
                                <div class="evaluate-star">
                                    <span> <!-- 本次访谈评价 --> {{ $t('myProject.evaluationInterview') }}</span>
                                    <el-rate :colors="colors" :max="3" class="star" v-model="star"
                                             void-color="#D6D6D6"></el-rate>
                                </div>
                                <div class="evaluate-content">
                  <textarea :placeholder="$t('myProject.pleaseInput')" cols="30" id="evaluate" maxlength="140"
                            name="evaluate"
                            rows="10" v-model="many.content">

                  </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="input-text-area" v-else>
                            <div class="ita-input-front" style="border-radius: 4px; height: 100%">
                <textarea :placeholder="$t('myProject.pleaseInput')" cols="30" id="evaluate" name="evaluate" rows="10"
                          style="
                    background-color: #fafafa;
                    width: 100%;
                    height: 149px;
                    margin-top: 17px;
                  " v-model="many.content">

                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span class="dialog-footer" slot="footer">
        <el-button @click="post_inviteeFeedback" style="
            width: 140px;
            height: 50px;
            border: 1px solid #707070;
            border-radius: 131px;
          ">
          <!-- 提交 -->
          {{ $t('myProject.submit') }}
        </el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import {customToDate, dDateValue} from "@/utils/dateUtils"
    import {vipPrivateRecommended} from "@/api/commonApi";
    import {dataTypePageTable, interviewProgressList} from "@/api/myProjectApi";
    import {
        initiatorFeedback,
        initiatorCancelReservation,
        vip_initiatorFeedback,
        post_initiatorCancel,
        initiatorEvaluatel,
        introduceTemplate, initiatorEvaluateForOther,
    } from "@/api/projectAbout";
    import {myVipProjectDetails} from "@/api/myProjectApi/publishedApi";
    import {selectCountriesCodeInfo} from '@/api/userApi'
    import ProgressBar from "@/components/progress_bar/ProgressBar";
    import {followTalent} from "@/api/memberApi";
    import {pageMixin} from "@/mixins";

    export default {
        name: "RecommendTable",
        inject: ['reload'],
        mixins: [pageMixin],
        data() {
            return {
                star: 3,
                inv: 0,
                isTab: 0,
                dialogVisible: false,
                pd_name: 0,
                dialogname: "",
                /*tabs: [],*/
                checked: false, //是否全选
                checkModel: [], //双向数据绑定的数组，我是用的id
                privacyList: [],
                privacyPageNum: 1,
                areaList: [],
                pageSize: 5,
                many: {
                    data_mailbox: "",
                    phone: "",
                    mobileCode: "86",
                    remarks: "",
                    data_whatsApp: "",
                    whatsappCode: "86",
                    content: "",
                },
                details_data: {},
                colors: ["#000000", "#F7BA2A", "#000000"],
            };
        },
        components: {ProgressBar},
        created() {
            //获取地区编号
            this.getArea();
            // TODO 存在问题：页面加载完成，再次切换语言无效
            //  created() 生命周期函数当 Vue 实例创建完成之后才会执行，且每次页面只会执行一次
            //  所以页面加载完成会根据你选择的语言来进行初始化，初始化完成后该函数不在执行，除非再次加载
            /*if (this.$route.query.pd_type == "2") {
              // VIP 私密推荐
              this.tabs = [this.$t('myProject.vipPrivateRecommendation')];
            } else {
              this.tabs = [
                // VIP 私密推荐
                this.$t('myProject.vipPrivateRecommendation'),
                // 感兴趣
                this.$t('myProject.beInterested'),
                // 会员推荐
                this.$t('myProject.memberRecommendation'),
                // 浏览过
                this.$t('myProject.have')
              ]
            }*/
            this.projectDetails();
        },

        props: ["projectId", "projectType"],
        methods: {
            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                switch (this.isTab) {
                    case 0:
                        this.getPrivacyList();
                        break;
                    default:
                        this.getOtherList();
                        break;
                }
            },
            goJumpPage(num) {
                this.pageNum = num;

                switch (this.isTab) {
                    case 0:
                        this.getPrivacyList();
                        break;
                    default:
                        this.getOtherList();
                        break;
                }
                // this.getBuyVipService();
            },


            isCan(value) {

                return dDateValue(customToDate(value), new Date()) > 2;

            },
            formatCode(code, en, cn) {
                if (this.$i18n.locale == 'zh') {
                    return "+" + code + "(" + cn + ")";
                } else {
                    return "+" + code + "(" + en + ")";

                }
            },
            print1(item) {
                console.log(item)

            },
            //关注
            attention(event, type, item) {
                if (event.target.disabled) {
                    // 点击太频繁了
                    this.$message.warning(this.$t('myProject.clickFrequentMsg'))
                    return
                }
                let formData = {
                    operateType: type,
                    talentId: item.invitee_id,
                    talentName: item.invitee_name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            this.clickFollowed = 1
                            this.$message.success(this.$t('myProject.followMsg.followSuccess'))//关注成功
                            item.followed = 1;

                        } else {
                            this.clickFollowed = 0
                            this.$message.success(this.$t('myProject.followMsg.unfollowSuccess'))//取消关注成功
                            item.followed = 0;

                        }
                    }
                })
            },
            async getInterviewProgressList(interviewId) {
                let res = await interviewProgressList({
                    interviewId: interviewId,
                    interviewType: 2,
                });
                if (res.code == 200) {
                    return res.data;
                } else {
                    return [];
                }
            },
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            get_introduceTemplate(item) {
                this.$router.push({
                    path: "/recommendationReason/" + this.$route.query.id,
                    query: {
                        coverUrl: this.details_data.project_cover_url,
                        title: this.details_data.project_name,
                        content: item.introduce_reason,
                    }
                });
            },
            //  项目详情
            projectDetails() {
                let formData = {
                    projectId: this.$route.query.id,
                };
                myVipProjectDetails(formData).then((res) => {
                    this.details_data = res.data;
                });
            },
            //   发起人取消预约
            async post_inviteeFeedback() {

                let formData = {
                    interviewId: this.inv,
                    token: localStorage.getItem("token"),
                    mobileNumber: this.many.phone,
                    mailBox: this.many.data_mailbox,
                    whatsApp: this.many.data_whatsApp,
                    mobileCode: this.many.mobileCode,
                    pdName: this.pd_name,
                    whatsappCode: this.many.whatsappCode,
                };
                if (this.pd_name == 0) {
                    formData.feedback = 2;
                    formData.rejectReason = this.many.content;
                    let res = await initiatorFeedback(formData);
                    if (res.code == 200) {
                        this.dialogVisible = false;
                        this.$message({
                            // 拒绝成功
                            message: this.$t('myProject.recommendTableMsg.refuseSucess'),
                            type: "success",
                        });
                        this.tabClick(this.isTab);
                    } else {
                        this.$message.error(res.msg)
                    }
                } else if (this.pd_name == 1) {
                    if (!this.many.content) return this.$message.warning(this.$i18n.locale == 'zh' ? "请填写取消原因!" : "Please fill in the reason for cancellation!")
                    formData.cancellationReason = this.many.content;
                    let res = await initiatorCancelReservation(formData);
                    if (res.code == 200) {
                        this.dialogVisible = false;
                        this.$message({
                            // 取消预约成功
                            message: this.$t('myProject.recommendTableMsg.cancelSucess'),
                            type: "success",
                        });
                        this.many.content = ""
                        this.tabClick(this.isTab);
                    } else {
                        this.$message.error(res.msg)
                    }
                } else if (this.pd_name == 2) {
                    formData.feedback = 2;
                    formData.rejectReason = this.many.content;

                    let res = await vip_initiatorFeedback(formData);
                    if (res.code == 200) {
                        this.dialogVisible = false;
                        this.$message({
                            // 拒绝成功
                            message: this.$t('myProject.recommendTableMsg.refuseSucess'),
                            type: "success",
                        });
                        this.tabClick(this.isTab);
                    } else {
                        this.$message.error(res.msg)
                    }
                } else if (this.pd_name == 3) {
                    if (!this.many.content) return this.$message.warning(this.$i18n.locale == 'zh' ? "请填写取消原因!" : "Please fill in the reason for cancellation!")
                    formData.cancelReason = this.many.content;
                    let res = await post_initiatorCancel(formData);
                    if (res.code == 200) {
                        this.dialogVisible = false;
                        this.$message({
                            // 取消预约成功
                            message: this.$t('myProject.recommendTableMsg.cancelSucess'),
                            type: "success",
                        });
                        this.tabClick(this.isTab);
                        this.many.content = ""

                    } else {
                        this.$message.error(res.msg)
                    }
                } else if (this.pd_name == 4) {
                    formData.rating = this.star;
                    formData.content = this.many.content;
                    if (this.isTab == 0) {
                        let res = await initiatorEvaluatel(formData);
                        if (res.code == 200) {
                            this.dialogVisible = false;
                            this.$message({
                                // 发表评论成功
                                message: this.$t('myProject.recommendTableMsg.postedCommentSuccess'),
                                type: "success",
                            });
                            this.tabClick(this.isTab);
                        } else {
                            this.$message.error(res.msg)
                        }
                    } else {
                        let res = await initiatorEvaluateForOther(formData);
                        if (res.code == 200) {
                            this.dialogVisible = false;
                            this.$message({
                                // 发表评论成功
                                message: this.$t('myProject.recommendTableMsg.postedCommentSuccess'),
                                type: "success",
                            });
                            this.tabClick(this.isTab);
                        } else {
                            this.$message.error(res.msg)
                        }
                    }


                }
            },
            // 单人同意跳转
            gopage(index, key) {
                let pd_num = 0;
                if (this.isTab == 0) {
                    pd_num = 1;
                } else {
                    pd_num = 2;
                }
                if (key.interview_id) {
                    key.id = key.interview_id;
                }

                this.$router.push("Multiperson?id=" + this.projectId + "&type=0&invnum=" + this.isTab + "&num=" +
                    key.invitee_id + "&inv=" + key.id + "&pd=" + pd_num);
            },
            tabClick(index) {
                this.isTab = index;
                this.pageNum = 1;
                if (index == 0) {
                    this.getPrivacyList();
                } else {
                    this.getOtherList();
                }
            },

            //  VIP私密推荐
            async getPrivacyList() {
                let formData = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectId: this.projectId,
                    sourceType: this.projectType,
                    token: localStorage.getItem("token"),
                };
                this.privacyList = [];
                let res = await vipPrivateRecommended(formData);
                if (res.code == 200) {
                    this.privacyList = res.data.list;
                    this.total = res.data.total;
                }
            },

            async getOtherList() {
                let formData = {
                    dataType: this.isTab,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectId: this.projectId,
                    token: localStorage.getItem("token"),
                };
                this.privacyList = [];
                let res = await dataTypePageTable(formData);
                if (res.code == 200) {
                    this.privacyList = res.data.rows;
                    this.total = res.data.total;
                }
            },
            checkAll() {
                if (this.checked) {
                    this.privacyList.forEach((item) => {
                        if (this.checkModel.indexOf(item.invitee_id) === -1) {
                            this.checkModel.push(item.invitee_id);
                        }
                    });
                } else {
                    this.checkModel = [];
                }
            },
        },
        watch: {
            checkModel() {
                this.checked = this.checkModel.length === this.privacyList.length;
                this.$emit("pop_data", this.checkModel, this.isTab);
            },
        },
        mounted() {
            this.getPrivacyList();
        },
    };
</script>
<style>
    .title-class .el-dialog__title {
        width: 96px;
        height: 31px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
    }
</style>
<style scoped>
    @import "../../../assets/css/element_reset.css";

    .evaluate-content {
        width: 695px;
        height: 121px;
        background: #fafafa;
        border-radius: 4px;
        margin-top: 30px;
        position: relative;
    }

    .evaluate-content textarea {
        width: 99%;
        height: 90px;
        background-color: transparent;
        resize: none;
        padding: 17px 26px;

        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .people-wrap {
        height: 49px;
        margin-top: 31px;
        line-height: 49px;
    }

    .people-wrap .people-head {
        display: inline-block;
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-wrap .people-name {
        display: inline-block;

        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-left: 12px;
    }

    .people-wrap .project-name {
        display: inline-block;

        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-left: 54px;
    }

    .evaluate {
        margin-top: 36px;
    }

    .evaluate-star {
        height: 25px;
        line-height: 25px;
    }

    .evaluate-star > span {
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 30px;
    }

    .evaluate-star > .star {
        display: inline-block;
        line-height: 20px;
    }

    h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .tabs > ul > li {
        cursor: pointer;
        display: inline-block;
        margin-right: 55px;

        font-size: 23px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
    }

    .tabClass {
        font-size: 24px !important;
        font-weight: bold !important;
        line-height: 31px !important;
        color: #000000 !important;
    }

    table {
        width: 1200px;
        margin-top: 26px;
    }

    table > thead > tr {
        height: 47px;
        background: #fafafa;
    }

    table > thead > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        text-align: center;
        vertical-align: middle;
    }

    table > thead > tr > td:first-child {
        box-sizing: border-box;
        width: 120px;
        text-align: left;
        padding-left: 18px;
    }

    table > tbody > tr > td:first-child {
        text-align: left;
        padding-left: 18px;
    }

    table > thead > tr > td:nth-child(2) {
        width: 192px;
    }

    .have-follow-col-2 {
        width: 366px !important;
    }

    table > thead > tr > td:nth-child(3) {
        width: 139px;
    }

    table > tbody > tr > td:nth-child(3) {
        box-sizing: border-box;

        text-align: left;
        padding-left: 26px;
    }

    table > thead > tr > td:nth-child(4) {
        width: 279px;
    }

    table > thead > tr > td:nth-child(5) {
        width: 216px;
    }

    table > tbody > tr {
        height: 113px;
    }

    table > tbody > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        text-align: center;
        vertical-align: middle;
    }

    .rpi-head {
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        vertical-align: middle;
    }

    .rpi-text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
    }

    .rpi-btn {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
        width: 99px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 32px;
    }

    .rpi-name {
        width: 115px;
        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
    }

    .rpi-position {
        width: 115px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 6px;
    }

    .r-limit {
        width: 112px;
    }

    .r-reason {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    .r-reason-model {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        text-decoration: underline;
    }

    .interview-time {
        box-sizing: border-box;
        width: 166px;
        height: 49px;
        background: #f1f1f1;
        border-radius: 131px;
        padding: 5px 37px;

        text-align: center;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
        margin: 0 auto;
    }

    .operation {
        text-align: center;
        /*padding-top: 10px;*/
    }

    .btn-op {
        width: 216px;
        height: 32px;
        overflow: hidden;
        margin: 0 auto;
    }

    .btn-op-item {
        cursor: pointer;
        box-sizing: border-box;
        width: 99px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 131px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .ban {
        border: 1px solid #f1f1f1;
    }

    .no-op {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .two-span {
        text-align: center;
    }

    .two-span p:first-child {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .two-span p:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #707070;
        margin-top: 6px;
    }

    /* checkbox样式*/
    input[type="checkbox"] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        width: 16px;
        height: 16px;
        bottom: 2px;
        margin-right: 5px;

        background: transparent;
        border: 1px solid #8a8a8a;
        /*border-radius: 4px;*/
        outline: none;
        cursor: pointer;
    }

    input[type="checkbox"] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        background: url("../../../assets/images/radio_n@2x.png") no-repeat;
        background-size: 100% 100%;
        border: 0;
    }

    input[type="checkbox"]:after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        text-align: center;
        line-height: 18px;
    }

    input[type="checkbox"]:checked:after {
        border-radius: 0;
        opacity: 1;
    }

    input[type="checkbox"]:checked {
        background: url("../../../assets/images/radio_s@2x.png") no-repeat;
        background-size: 100% 100%;
    }

    .form-wrap {
        margin-top: 0px;
    }

    .input-text-area {
        margin-bottom: 31px;
    }

    .input-text-area:last-child {
        margin-bottom: 0;
    }

    .ita-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
    }

    .ita-label > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .ita-input-front {
        width: 536px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 26px;
        line-height: 50px;
    }

    .ita-input-fronts {
        width: 300px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 50px;
        line-height: 50px;
    }

    .s-input {
        display: inline-block;
        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 60px;
    }

    .iif-input {
        display: inline-block;

        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 300px;
    }

    .v-line {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 22px;
        border: 1px solid #d6d6d6;
        margin-right: 45px;
    }

    .input_box_number {
        width: 695px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding-left: 26px;
        box-sizing: border-box;
        margin-left: 11px;
    }

    .input_box_number span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .input_box_number input {
        margin-left: 53px;
        padding-left: 45px;
        height: 22px;
        background: #FAFAFA;
        border-left: 1px solid #d6d6d6;
        width: 500px;
        box-sizing: border-box;
    }
</style>
