<template>
    <div class="index-container">
        <BreadcrumbNav :page-name="$t('myPublishedProjects.projectDetails')"/>
        <div class="main">
            <div class="text-group2">
                <h1>{{ projectMsg.project_name }}</h1>
                <div class="text-group2-content">
                    <!-- yyyy-MM-dd HH:mm:ss 发布 -->
                    <div class="publish-date fl" style="width: 15%">{{ projectMsg.publish_time }} {{
                        $t('myProject.publish') }}
                    </div>
                    <div class="brief-intro over fl" style="width: 50%">{{ projectMsg.project_intro }}</div>
                </div>
                <!-- 编辑按钮 -->
                <div @click="$router.push({
             path:'/project/editProject/'+projectId,
             query:{
               isInterview:false,
             }
             })"
                     class="see-detail-btn"
                     style="right: 300px;border-color: dodgerblue;color: dodgerblue">
                    {{ $t('myProject.edit') }}
                </div>
                <!-- 删除按钮 -->
                <div @click="delProjectById"
                     class="see-detail-btn"
                     style="right: 150px;border-color: red;color: red">
                    {{ $t('myProject.delete') }}
                </div>
                <!-- 查看详情 -->
                <div @click="gopage('/project/details/')" class="see-detail-btn">
                    {{ $t('myProject.detail') }}
                </div>

            </div>
            <div class="people-wrap">
                <div class="people-info fl">
                    <img :src="projectMsg.initiator_avatar" alt="" class="head-icon fl"/>
                    <div class="people-info-text fl">
                        <h1 class="over">{{ projectMsg.initiator }}</h1>
                        <!-- VIP 到期时间 -->
                        <h2 class="over">{{ $t('myProject.vipExpireTime') + ' ' + projectMsg.vip_end_date }}</h2>
                    </div>
                </div>
                <div class="btn-group fl">
                    <!--          1，3：找人，找智慧；2：融资；-->
                    <CustomButton
                            @click.native="$router.push('/VIP?type=' + projectMsg.project_genre+'&projectId='+$route.query.id)"
                            class="fl"
                            type="black">
                        <!-- VIP 购买 -->
                        {{ $t('myProject.vipBuy') }}
                    </CustomButton>
                </div>
            </div>
            <!--VIP订单-->
            <OrderTable :project-id="this.$route.query.id" :sourceType="1" style="margin-top: 27px" v-if="VIPList"/>
            <hr/>
            <!--VIP私密推荐、{感兴趣,会员推荐,浏览过}-->
            <RecommendTable
                    :projectId="projectId"
                    :projectType="projectType"
                    style="margin-top: 65px"
                    v-on:pop_data="pop_data"/>
            <hr/>
            <div @click="startMulti()">
                <CustomButton style="margin-top: 30px" type="black">
                    <!-- 发起多人沟通 -->
                    {{ $t('myProject.multiPersonCommunication') }}
                </CustomButton>
            </div>

            <!--多人邀约记录-->
            <MorePersonInviteTable :project-id="projectId" style="margin-top: 65px"/>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import OrderTable from "@/components/my_project/OrderTable";
    import RecommendTable from "@/components/my_project/my_published_projects/RecommendTable";
    import MorePersonInviteTable from "@/components/my_project/my_published_projects/MorePersonInviteTable";
    import {myVipProjectDetails, invitePeopleRecord, delProjectById} from "@/api/myProjectApi/publishedApi";
    import {buyVipService} from "@/api/commonApi";

    export default {
        name: "index",
        data() {
            return {
                projectId: "",
                projectMsg: "",
                VIPpageNum: 1,
                VIPList: [],
                VIPList2: [],
                projectType: "",
                invitePageNum: 1,
                //   组件传的选择数组
                selected_array: [],
                invnum: 0,
            };
        },
        components: {
            BreadcrumbNav,
            CustomButton,
            OrderTable,
            RecommendTable,
            MorePersonInviteTable,
        },
        props: {},
        created() {
            this.projectId = this.$route.query.id;
            this.projectType = this.$route.query.type;
            this.projectDetails();
            //this.getInvitePeopleRecord();
        },
        mounted() {
            // this.getVIPList();
        },
        methods: {
            //发起多人沟通
            startMulti() {
                if (this.selected_array.length <= 1) {
                    this.$message.warning("Select at least two people！")//至少选择两个人
                    return;
                }
                this.$router.push({
                    path: 'Multiperson?id=' +
                        this.projectId +
                        '&type=1&invnum=' +
                        this.invnum +
                        '&num=' +
                        this.selected_array +
                        '&pd=3',
                    query: {
                        type: 1
                    }
                })
            },
            //   私密推荐传值
            pop_data(key, num) {
                this.selected_array = key;
                this.invnum = num;
            },
            //  项目详情
            projectDetails() {
                let formData = {
                    projectId: this.projectId,
                };
                myVipProjectDetails(formData).then((res) => {
                    this.projectMsg = res.data;
                });
            },
            //  项目删除
            delProjectById() {
                let formData = {
                    token: localStorage.getItem("token"),
                    projectType: 1,
                    projectId: this.projectId,
                };
                this.$confirm(this.$t('myProject.confirmDel'), this.$t('publish.tips'), {
                    confirmButtonText: this.$t('publish.determine'),
                    cancelButtonText: this.$t('publish.cancel'),
                    type: 'warning'
                }).then(() => {
                    return delProjectById(formData).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.msg)
                            this.$router.go(-1)
                        } else {
                            this.$message.error(res.msg)
                        }
                    });
                });

            },

            //
            gopage(url) {
                this.$router.push({
                    path: url + this.$route.query.id,
                    query: {
                        type: 1,
                    }
                });
            },

            //VIP订单
            getVIPList() {
                let formData = {
                    pageNum: this.VIPpageNum,
                    pageSize: 10,
                    projectId: this.projectId,
                    sourceType: 1,
                    token: localStorage.getItem("token"),
                };
                buyVipService(formData).then((res) => {
                    if (res.data) {
                        this.VIPList = res.data.list;
                    }
                });
            },


        },

    };
</script>

<style scoped>
    .index-container {
        background-color: #ffffff;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        padding-bottom: 24px;
        margin: 0 auto;
    }

    .text-group2 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 30px;
        position: relative;
    }

    .see-detail-btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 25px;
        width: 124px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 40px;
    }

    .text-group2-content {
        margin-top: 8px;
    }

    .publish-date {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .brief-intro {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-left: 16px;
    }

    .text-group2 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .people-wrap {
        padding: 38px 0;
        overflow: hidden;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        margin-left: 4px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    hr {
        background-color: #e5e5e5;
    }
</style>
