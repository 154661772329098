<template>
    <div class="MorePersonInviteTable-container">
        <h1>
            <!-- 多人邀约记录 -->
            {{ $t('myProject.multiPersonInvitationRecord') }}
        </h1>
        <table>
            <thead>
            <tr>
                <!-- 多人沟通时间 -->
                <td>{{ $t('myProject.multiPersonCommunicationTime') }}</td>
                <!-- 邀约人进度 -->
                <td>{{ $t('myProject.progressOfInvitees') }}</td>
                <!-- 已同意 -->
                <td>{{ $t('myProject.agreed') }}</td>
                <!-- 待反馈 -->
                <td>{{ $t('myProject.waitingForFeedback') }}</td>
                <!-- 发起邀约日期 -->
                <td>{{ $t('myProject.dateOfInvitation') }}</td>
                <!-- 操作 -->
                <td>{{ $t('myProject.operation') }}</td>
            </tr>
            </thead>
            <tbody>
            <tr :key="index" v-for="(item, index) in list" v-show="list.length > 0">
                <td>
                    <p>{{ item.invitation_time }}</p>
                </td>
                <td>
                    <!-- 邀约人数 -->
                    <p>{{ $t('myProject.invitationNum') }} {{ item.invite_amount }}</p>
                    <!-- 已同意人数 -->
                    <p>{{ $t('myProject.agreedNum') }} {{ item.consent_number }}</p>
                </td>
                <td v-if="item.agreeInviteList">
                    <div style="display: flex; flex-wrap: wrap">
                        <div :key="index2" v-for="(items, index2) in item.agreeInviteList">
                            <div v-if="items != null">
                                <span @click="gopage(items)" class="cur">{{ items.invitee || "" }}</span>
                                <span style="margin: 0 5px" v-if="item.agreeInviteList.length != index2 + 1">/</span>
                            </div>
                        </div>
                    </div>
                </td>

                <td v-if="item.untreatedInviteList">
                    <!-- <div v-if="item.toFeedback.length >= 1"> -->
                    <div style="display: flex; flex-wrap: wrap">
                        <div :key="index2" v-for="(items, index2) in item.untreatedInviteList">
                            <div v-if="items != null">
                                <span @click="gopage(items)" class="cur">{{ items.invitee || "" }}</span>
                                <span style="margin: 0 5px"
                                      v-if="item.untreatedInviteList.length != index2 + 1">/</span>
                            </div>
                        </div>
                    </div>
                </td>
                <td v-else>
                    <span>-</span>
                </td>
                <td>{{ item.create_time }}</td>
                <td>
                    <div @click="$router.push({ path:'/videoRoom', query:{ interviewId:item.id, type:4, isPublisher:1, } })"
                         class="btn-op-item cur fl" style="margin-left: 10px"
                         v-if="item.consent_number > 0 && item.isFangTan ==1">
                        <!-- 访谈 -->
                        {{ $t('myProject.interview') }}
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <el-pagination :current-page.sync="pageNum" :page-size="pageSize" :total="total"
                       @current-change="handleCurrentChange" layout="prev, pager, next,slot" style="text-align: end">
            <slot>
                <div class="jump-page">
          <span>
            <!-- 前往 -->
            {{ $t('myProject.goTo') }}
          </span>
                    <input type="text" v-model.number="goPageNum"/>
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
    </div>
</template>

<script>
    import {invitePeopleRecord, multiInviteDetail} from "@/api/myProjectApi";
    import {pageMixin} from "@/mixins";

    export default {
        name: "MorePersonInviteTable",
        mixins: [pageMixin],
        data() {
            return {
                pageSize: 5,
            };
        },
        components: {},
        props: ["projectId"],
        mounted() {
            this.getInvitePeopleRecord();
        },
        methods: {
            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getInvitePeopleRecord();
            },
            goJumpPage(num) {
                this.pageNum = num;
                this.getInvitePeopleRecord();
            },
            gopage(item) {
                // this.$router.push(url);
                this.$router.push({
                    path: '/user/index', query: {
                        userType: item.userType,
                        talentId: item.invitee_id,
                    }
                })
            },
            //多人邀约
            getInvitePeopleRecord() {
                let formData = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectId: this.projectId,
                };
                let _that = this;
                invitePeopleRecord(formData).then((res) => {
                    _that.list = res.data.rows;
                    _that.total = res.data.total;
                });
            },
        },
    };
</script>

<style scoped>
    .btn-op-item {
        cursor: pointer;
        box-sizing: border-box;
        width: 99px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 131px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    table {
        width: 1200px;
        margin-top: 26px;
    }

    table > thead > tr {
        height: 47px;
        background: #fafafa;
    }

    table > thead > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        text-align: left;
        vertical-align: middle;
    }

    table > thead > tr > td:first-child {
        text-align: center;
        width: 160px;
    }

    table > thead > tr > td:nth-child(2) {
        width: 161px;
    }

    table > thead > tr > td:nth-child(3) {
        width: 370px;
    }

    table > thead > tr > td:nth-child(4) {
        /*width: 402px;*/
    }

    table > thead > tr > td:nth-child(5) {
        width: 100px;

    }

    table > thead > tr > td:nth-child(6) {
        width: 99px;
        text-align: center;
    }

    table > tbody > tr {
        height: 83px;
    }

    table > tbody > tr > td {
        text-align: left;
        font-family: Microsoft YaHei;
        vertical-align: middle;
    }

    table > tbody > tr > td:first-child {
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
    }

    table > tbody > tr > td:nth-child(2) {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    table > tbody > tr > td:nth-child(3) {
        font-size: 15px;
        font-weight: normal;
        line-height: 24px;
        color: #000000;
    }

    table > tbody > tr > td:nth-child(4) {
        font-size: 15px;
        font-weight: normal;
        line-height: 24px;
        color: #000000;
    }

    table > tbody > tr > td:nth-child(5) {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    table > tbody > tr > td:nth-child(6) {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        width: 99px;

    }
</style>
